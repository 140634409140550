import "./App.css";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useEffect } from "react";
import { isMobile} from 'react-device-detect';

const firebaseConfig = {
  apiKey: "AIzaSyAE75DhAr5IRn9X2zlKVq9fIwPe1pFX_Mg",
  authDomain: "widgetable-offical.firebaseapp.com",
  projectId: "widgetable-offical",
  storageBucket: "widgetable-offical.appspot.com",
  messagingSenderId: "990984616308",
  appId: "1:990984616308:web:30d63c66c9f84cc7871421",
  measurementId: "G-9ML7D9RLPV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const DOWNLOAD_LINK = {
  android: "https://play.google.com/store/apps/details?id=com.widgetable.theme.android&referrer=utm_source%3Dwebsite_gp",
  ios: "https://apps.apple.com/us/app/widgetable-lock-screen-widget/id1641107226?ppid=412e53d5-7f72-4228-a20a-374824e08e07",
};

function App() {
  /**
   * @param {String} platform  "GP" | "IOS"
   */
  const handleDownload = (platform) => {
    const url = platform === "GP" ? DOWNLOAD_LINK.android : DOWNLOAD_LINK.ios;
    url && window.location.assign(url);
    logEvent(analytics, 'download_click', {"platform":platform, "link" : url});
  };

  useEffect(() => {
    logEvent(analytics, 'app_start', {"user-agent":navigator.userAgent});
    const url = window.location.href;
    if (isMobile && ['/friend/code/', '/pet/code/', '/pet/gift/', '/pet/coparenting/', '/widget/'].filter((e) => url.includes(e)).length > 0) {
      const deeplink = url.replace('http://' + window.location.host, 'widgetable://jump.to').replace('https://' + window.location.host, 'widgetable://jump.to');
      console.log(deeplink);
      window.location.href = deeplink;
    }
  })
  return (
    <div id="widgetable-wrapper">
      <div className="logo">
        {/* Widgetable */}
        <img src={require("./images/logo.png")} alt="" />
      </div>
      <div className="slogen">Widgetable makes your screen adorable.</div>
      <section className="example">
        <img src={require("./images/phone.png")} alt="" />
      </section>
        <div
            className="download-ios"
            onClick={() => {
                handleDownload("IOS");
            }}
        >
        </div>

        <div
            className="download-android"
            onClick={() => {
                handleDownload("GP");
            }}
        >
        </div>

      <footer>
        <div className="contect-email">
          Email:{" "}
          <a href="mailto:service@widgetable.net">service@widgetable.net</a>
        </div>
        Copyright © 2022 Happeny Technology Pte. Ltd.
        <div className="link-wrapper">
          <a className="link-policy" href="/privacy.html">
            Privacy Policy
          </a>
          <span className="split">|</span>
          <a className="link-terms" href="/terms.html">
            Terms of Service
          </a>
        </div>
      </footer>
    </div>
  );
}

export default App;
